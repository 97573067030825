
:root {
  --accent-neg-2: #9D31F5;
  --accent-neg-1: #8E5BB7;
  --primary: #6B21A8;
  --accent-1: #BE7AF5;
  --accent-2: #4B1775;
}

body {
  margin: 0; 
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
	margin-top: var(--navbar-height, 0);
	min-height: calc(var(--window-height) - var(--navbar-height));
}

.fill-container, .fill-parent {
	height: 100%;
	width: 100%;
}

.page-size {
	width: var(--window-width);
	height: calc(var(--window-height) - var(--navbar-height));
}

.vh-align, .vhalign {
	display: flex;
	align-items: center;
	justify-content: center;
}

.v-align, .valign {
	display: flex;
	align-items: center;
}

.h-align, .halign {
	display: flex;
	justify-content: center;
}

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.grey-text {
	color: gray;
}

.white-text {
	color: white;
}

.primary-text {
	color: var(--primary);
}

.red-bg {
	background-color: red;
}

.white-bg {
	background-color: white;
}

.primary-bg {
	background-color: var(--primary);
}

.mui-table thead th {
	font-weight: bold !important;
}

.font-30 {
	font-size: 30px;
}

.margin-20 {
	margin: 20px;
}

.form > * {
	margin: 10px auto !important;
}

.MuiButton-root {
	z-index: 0 !important;
}

.glass {
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.2);
}